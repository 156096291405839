import { ROLE } from "../components/common";
import { AccessRequestStatus, Role } from "../services/users_v1";

export const ORDER_STATUSES = {
    [AccessRequestStatus.OPEN]: 'Orders status open',
    [AccessRequestStatus.PENDING]: 'Orders status pending',
    [AccessRequestStatus.GRANTED]: 'Orders status granted',
    [AccessRequestStatus.REJECTED]: 'Orders status rejected',
    [AccessRequestStatus.CANCELED]: 'Canceled',
    [AccessRequestStatus.REDIRECTED]: 'Redirected',
    [AccessRequestStatus.COPIED]: 'Copied',
    [AccessRequestStatus.CLOSED]: 'Closed',
    [AccessRequestStatus.FORWARDED]: 'Forwarded',
    [AccessRequestStatus.ARCHIVED]: 'Archived'
};

export const ACCOUNT_TYPES: { [key: number]: string; } = {
    0: 'Владельцы сайта',
    1: 'Поставщик',
    2: 'Потребитель',
    3: 'Партнер',
    4: 'Партнер модератор',
    5: 'Партнер источник Медиалогистика',
    6: 'Партнер источник',
};

export const ORG_TYPES: { [key: number]: string; } = {
    0: 'Организация',
    1: 'ИП',
    2: 'Самозанятый',
    3: 'Физическое лицо',
};

export const EMPTY_ROLE: Role = {
    id: NaN,
    label: ROLE.UNKNOWN,
    name: ROLE.UNKNOWN
};

// SATELLITE = 1, MEDIALOGISTIKA = 3, OTHER = 4, ANY = 5
export const SOURCE_MAP = {
    1: 'ФГУП Космическая связь',
    2: 'Кабель',
    3: 'АО ЦВКС MSK-IX',
    4: 'Другой',
    5: 'Любой',
};

export const MEDIABAZA_LANGS = [
    { value: 'ru', label: 'Русский' },
    { value: 'en', label: 'English' },
];

