import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { extractErrorDetail } from '../components/common';
import Button from '../components/mplat/Button/Button';
import Content from '../components/mplat/Content';
import ContentShadow from '../components/mplat/ContentShadow';
import Form from '../components/mplat/Form';
import Header1 from '../components/mplat/Header1';
import { ErrorModal } from '../components/mplat/Modals/ErrorModal';
import Section from '../components/mplat/Section';
import TextInput from '../components/mplat/TextInput';
import { EMPTY_ROLE } from '../const';
import { useAuth } from '../hooks';
import { setCredentials, setRole } from '../services/api';
import {
    useConfirmActivationMutation,
    useConfirmPasswordMutation,
    useValidateActivationMutation, useValidateChangePasswordMutation
} from '../services/users_v1';

interface ResetFormPageProps {
    reset?: boolean
}

function ResetFormPage({ reset }: ResetFormPageProps) {
    const [activate] = useConfirmActivationMutation();
    const [confirmPassword] = useConfirmPasswordMutation();
    const [checkActivate] = useValidateActivationMutation();
    const [checkPassword] = useValidateChangePasswordMutation();

    const auth = useAuth();
    const dispatch = useDispatch();
    const [params] = useSearchParams();

    const queryUid = params.get('uid') || '';
    const queryToken = params.get('token') || '';

    const [password, setPassword] = useState('');
    const [password2, setPassword2] = useState('');
    const [token, setToken] = useState(queryToken);
    const [uid, setUID] = useState(queryUid);
    const [error, setError] = useState('');
    const [errorTitle, setErrorTitle] = useState('');
    const [valid, setValid] = useState(false);
    const [showError, setShowError] = useState(false);
    const [isSuccess, setIsSuccess] = useState<boolean | undefined>(undefined);
    const { t } = useTranslation();

    useEffect(() => {
        // logout чтобы не активировать через авторизованного
        if (auth.user) {
            delete localStorage.user;
            delete localStorage.suUser;
            delete localStorage.suRefresh;
            delete localStorage.refreshToken;
            delete localStorage.accessToken;
            dispatch(setCredentials({ user: '', accessToken: '', refreshToken: '' }));
            dispatch(setRole(EMPTY_ROLE));
        }
    }, [auth, dispatch]);

    useEffect(() => {
        if (queryUid && queryToken) {
            if (!reset) {
                checkActivate({ uid: queryUid, token: queryToken }).unwrap().then(() => {
                    console.log('Checked activate');
                }).catch((e) => {
                    console.warn(JSON.stringify(e));
                    setIsSuccess(false);
                });
            } else {
                checkPassword({ uid: queryUid, token: queryToken }).unwrap().then(() => {
                    console.log('Checked password');
                }).catch((e) => {
                    console.warn(JSON.stringify(e));
                    setIsSuccess(false);
                });
            }
        }
        // eslint-disable-next-line
    }, []);

    return (
        <Content>
            <ContentShadow />
            <ErrorModal isOpen={!!error} onClose={() => setError('')} title={errorTitle} content={error} />
            <Form action="#" className="content-add">
                {isSuccess === true
                    ?
                    <Header1>
                        <Trans>
                            Password successfully changed
                        </Trans>
                    </Header1>
                    :
                    (
                        isSuccess === false
                            ?
                            <Header1>
                                <Trans>
                                    Token is invalid or expired
                                </Trans>
                            </Header1>
                            :
                            <>
                                <Header1></Header1>
                                <Section label={reset ? t('Change password') : t('Activate account')}>
                                    {queryUid ? null : <TextInput placeholder="uid" value={uid} onChange={(e) => setUID(e.target.value)} />}
                                    {queryToken ? null : <TextInput placeholder={t('Token')} value={token} onChange={(e) => setToken(e.target.value)} />}
                                    <TextInput
                                        type="password"
                                        placeholder={reset ? t('New password') : t('Password')}
                                        value={password}
                                        onChange={(e) => { setPassword(e.target.value); setValid(password2 === e.target.value); }
                                        }
                                    />
                                    <TextInput
                                        type="password"
                                        placeholder={t('Repeat password')}
                                        value={password2}
                                        onChange={
                                            (e) => { setPassword2(e.target.value); setValid(password === e.target.value) }
                                        }
                                    />
                                    {(showError && !valid) ?
                                        <div style={{ color: 'red' }}>
                                            <Trans>Passwords do NOT match</Trans>
                                        </div>
                                        :
                                        null
                                    }
                                    <div style={{ textAlign: 'center' }}>
                                        <Button
                                            onClick={(e) => {
                                                e.preventDefault();
                                                if (!valid) {
                                                    setShowError(true);
                                                    return;
                                                }
                                                const action = reset ? confirmPassword : activate;
                                                action({ uid: uid, token: token, password: password }).unwrap().then(() => {
                                                    setIsSuccess(true);
                                                }).catch((err) => {
                                                    setErrorTitle(t('Error'));
                                                    setError(extractErrorDetail(err));
                                                });
                                            }}
                                        // disabled={!valid}
                                        >
                                            {reset ? t('Change password') : t('Activate')}
                                        </Button>
                                    </div>
                                </Section>
                            </>
                    )
                }
            </Form>
        </Content>
    );
}

export default ResetFormPage;
